import React, { useState, ReactNode, createContext } from "react";

const BlogBackContext = createContext({ page: 0, setPage: () => {} });

const BlogBackProvider = ({ children }: { children: ReactNode }) => {
  const [page, setPage] = useState(0);

  return (
    <BlogBackContext.Provider value={{ page, setPage }}>
      {children}
    </BlogBackContext.Provider>
  );
};

export { BlogBackContext, BlogBackProvider };
