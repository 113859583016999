/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require("./node_modules/bootstrap/dist/css/bootstrap.css");
require("./src/components/blocks/library/BlockTestimony/CardSlider.css")
import "./node_modules/bootstrap/dist/css/bootstrap.css";
import Layout from "./src/components/layout/MainLayout";
import * as React from "react";
import type { GatsbyBrowser } from "gatsby";
import { BlogBackProvider } from "./src/context/BlogBackContext";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return <BlogBackProvider>{element}</BlogBackProvider>;
};
